<template>
	<app-layout v-loading="pageLoading" size="none">
		<div style="padding: 0 16px; margin: 10px 0">
			<el-table :data="tableData" class="tableBackground" :row-key="getRowKey">
				<el-table-column label="序号" align="center" width="80">
					<template v-slot="{ $index }">{{ $index + 1 }}</template>
				</el-table-column>
				<el-table-column label="规划名称" align="center" prop="planName"></el-table-column>
				<el-table-column label="状态" align="center" prop="statusName"></el-table-column>
				<el-table-column label="启动时间" min-width="130" align="center" prop="planStartTime"></el-table-column>
				<el-table-column label="结束时间" min-width="130" align="center" prop="planEndTime"></el-table-column>
				<el-table-column label="一级部门" align="center" prop="orgNameOne"></el-table-column>
				<el-table-column label="二级部门" align="center" prop="orgNameTwo"></el-table-column>
				<el-table-column label="部门人数" align="center" prop="number"></el-table-column>
				<el-table-column label="更新时间" align="center" min-width="130" prop="updateTime"></el-table-column>
				<el-table-column label="操作" align="center" width="140">
					<template v-slot="{ row }">
						<!--<el-popover placement="left"
                        width="96"
                        trigger="click">
              <el-button type="text"
                         slot="reference">
                <img src="../../assets/button/操作图标@3x.png" width="16px" />
              </el-button>
              <ul class="drop-menu">
                <li class="menu-item"
                    v-for="(item, i) in commands"
                    @click="operate(row,item)"
                    :key="i">{{item.label}}</li>
              </ul>
            </el-popover>!-->
						<el-button type="text" size="small" v-for="(item, i) in commands" @click="operate(row, item)" :key="i">{{
							item.label
						}}</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination
			background
			slot="footer"
			:current-page="apiParams.pageNo"
			:page-size="apiParams.pageSize"
			@current-change="handleCurrentChange"
			layout="prev, pager, next"
			:page-count="totalPage"
		>
		</el-pagination>
		<el-dialog :title="title" :visible.sync="dialog" center width="30%" class="messageDigBox">
			<span class="label">{{ title == '通过审批' ? '通过说明' : '不通过原因' }}</span>
			<el-input type="textarea" :rows="3" :maxlength="50" placeholder="1-50个字符" v-model="remark"> </el-input>
			<span slot="footer">
				<el-button @click="dialog = false">取 消</el-button>
				<el-button type="primary" :loading="btnloading" @click="apporoveSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</app-layout>
</template>

<script>
import { approvelist, approvesubmit } from '../../api/staffSystem';
import PageMixin from '../../mixins/pageQuery';
export default {
	mixins: [PageMixin],
	data() {
		return {
			tableData: [1],
			totalPage: 0,
			commands: [
				{ label: '详情', value: '0' },
				{ label: '通过', value: '1' },
				{ label: '不通过', value: '2' }
			],
			pageLoading: false,
			dialog: false,
			btnloading: false,
			approvalId: '',
			remark: '',
			title: '',
			apiParams: {
				pageNo: 1,
				pageSize: 50
			}
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getRowKey(row) {
			return row.id;
		},
		async getData() {
			this.pageLoading = true;
			const res = await approvelist({ request: this.apiParams });

			if (res.list) {
				this.tableData = res.list;
			}
			this.totalPage = res.totalPage;
			this.pageLoading = false;
		},
		async apporoveSubmit() {
			this.btnloading = true;
			if (!this.remark && this.title == '审批不通过') {
				this.$message.warning('请输入不通过原因');
			} else {
				const res = await approvesubmit({
					approvalId: this.approvalId,
					status: this.title === '审批不通过' ? '3' : '2',
					remark: this.remark
				});

				if (res.code == 0) {
					this.dialog = false;
					this.$message.success('审批已提交');
					this.getData();
				}
			}
			this.btnloading = false;
		},
		operate(row, command) {
			if (command.value != 0) {
				this.approvalId = row.approvalId;
				if (command.value == '1') {
					this.title = '通过审批';
				} else {
					this.title = '审批不通过';
				}
				this.dialog = true;
			} else {
				this.$router.push({ path: '/staff/talent_aduit', query: { planId: row.planId, approvalId: row.approvalId } });
			}
		}
	}
};
</script>

<style lang="scss">
.messageDigBox {
	.label {
		margin-right: 10px;
		vertical-align: top;
		display: inline-block;
		transform: translateY(6px);
	}
	.el-textarea {
		width: 70%;
	}
	.el-dialog__footer {
		text-align: center;
		margin-top: 25px;
	}
}
</style>
