export default {
    data () {
        return {
            totalNum: 0,
            totalPage: 0,
            pageLoading: false
        }
    },
    methods: {
        handleCurrentChange (pageNo) {
            if (this.apiParams.pageNo == pageNo) return;
            this.apiParams.pageNo = pageNo;
            this.getPageData();
        },
        handleSizeChange (pageSize) {
            this.apiParams.pageSize = pageSize;
            this.filter();
        },
        filter () {
            this.apiParams.pageNo = 1;
            this.getPageData();
        },
        queryToApiParams () {
            let params = {};
            if (this.apiParamsType) {
                for (let key in this.$route.query) {
                    if (this.$route.query[key] === null || this.$route.query[key] === '') {
                        continue;
                    }
                    if (this.apiParamsType[key] !== undefined && typeof this.apiParamsType[key] == 'function') {
                        params[key] = this.apiParamsType[key](this.$route.query[key]);
                    } else if (this.apiParamsType[key] !== undefined && typeof this.apiParamsType[key] == 'object') {
                        params[key] = this.apiParamsType[key].toApiParams(this.$route.query[key]);
                    } else {
                        params[key] = this.$route.query[key];
                    }
                }
            } else {
                params = this.$route.query;
            }
            if (params.pageSize !== undefined) {
                params.pageSize = Number(params.pageSize);
            }

            if (params.pageNo !== undefined) {
                params.pageNo = Number(params.pageNo);
            }
            this.apiParams = Object.assign(this.apiParams, {
                ...params
            });
        },
        apiParamsToQuery () {
            let apiParams = {};
            for (let key in this.apiParams) {
                if (this.apiParams[key] === '' || this.apiParams[key] === null) {
                    continue;
                }
                if (this.apiParamsType && this.apiParamsType[key] !== undefined && typeof this.apiParamsType[key] === 'object') {
                    apiParams[key] = this.apiParamsType[key].toQuery(this.apiParams[key]);
                } else {
                    apiParams[key] = this.apiParams[key];
                }
            }
            this.$router.replace({
                query: apiParams
            });
            return apiParams;
        }
    }
};